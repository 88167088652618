@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,500;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,300&display=swap");
/* font-family: 'Montserrat', sans-serif; */

/* font-family: 'Roboto', sans-serif; */
/* font-family: 'Sen', sans-serif; */

::selection {
  background: black;
  color: white;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  position: relative;
  /* cursor: none; */
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}
.cursor {
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #121212;
  border-radius: 100%;
}

.wrapper {
  width: 100%;
  height: 100vh;
}
header {
  width: 100%;
  height: 100vh;
  display: flex;
  font-size: 4vw;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: rgb(41, 41, 41);
  background: #ffefba; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #ffffff,
    #ffefba
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #ffffff,
    #ffefba
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.center-content {
  display: flex;
  font-size: 4vw;
  justify-content: center;
  align-items: center;
}
header h1 {
  font-weight: 400;
}

.logo {
  position: absolute;
  top: 0;
  left: 50px;
  background-color: rgb(251, 175, 33);
  color: white;
  padding: 20px 10px;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  letter-spacing: 4px;
}

/* Projectss Projectss Projectss Projectss  ----------------- */
.projects {
  width: 90%;
  height: 90%;
  margin: 100px auto;
}
.projects h1 {
  color: #121212;
  text-transform: uppercase;
}

/* skills skills skills skills skills  ---------------- */
.skills {
  width: 90%;
  margin: 150px auto;
  color: #121212;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
}
.skills .github {
  margin-top: 80px;
  text-align: right;
}
.skills button {
  width: 150px;
  padding: 20px 0;
  border: none;
  cursor: pointer;
  background-color: #f4cbb2;
  color: rgb(70, 67, 67);
  transition: 0.5s ease-in-out;
  text-transform: uppercase;
}
.skills button:active {
  border: none;
}
.skills .resume {
  margin-top: 50px;
}
.skills button:hover {
  background-color: #9cdaf1;
  color: white;
}
.skills .resume .button {
  background-color: #7dbbe6;
}

/* footer footer footer footer footer  */
footer {
  background-color: rgb(249, 249, 249);
}
footer div {
  width: 90%;
  margin: 0 auto;
  padding: 10px 0;
}
footer a {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  color: black;
  text-decoration: none;
}
.space {
  height: 200vh;
}
@media (max-width: 700px) {
  footer {
    text-align: center;
  }
}
